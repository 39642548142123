import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

const Legal = ({ data }) => {
  const {
    html,
    frontmatter: { title }
  } = data.markdownRemark
  return (
    <Layout title="Legal">
      <div className="main-content post-main mt-3 p-3">
        <h2 className="heading mb-5">{title}</h2>
        <div className="mt-4" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout >
  )
}

export const query = graphql`
  query Legal {
    markdownRemark(frontmatter: {type: {eq: "legal"}}) {
      frontmatter {
        title
      }
      html
    }
  }
`

export default Legal